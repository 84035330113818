@import "01_setting/_var.scss";
@import "01_setting/_mixin.scss";

/*=============================================================
 home トップページ
=============================================================*/
/* #catsNav
-------------------------------------------------*/
#catsNav { 
	@media all and (min-width: 600px) { 
		border-bottom: 1px solid #ededed;
		overflow: hidden;
		.list { 
			&.temporary { 
				width:400px;
				.item { 
					width:50%;
				}
			}
			position: relative;
			letter-spacing: -.5em;
			&::after { 
				position: absolute;
				top: 34px;
				left: 0;
				content: '';
				width: 1px;
				height: 30px;
				background: #dcdcdc;
			}
			.item { 
				display: inline-block;
				width: (100/7) + 0%;
				box-sizing: border-box;
				letter-spacing: .04em;
				a { 
					position: relative;
					display: block;
					height: 82px;
					padding: 17px 0 0;
					background: #fff;
					text-align: center;
					&::after { 
						position: absolute;
						top: 34px;
						right: 0;
						content: '';
						width: 1px;
						height: 30px;
						background: #dcdcdc;
					}
					.ico { 
						display: block;
						margin-bottom: 7px;
						img { 
							display: block;
							width: 42px;
							margin: 0 auto;
						}
					}
					.txt { 
						position: relative;
						display: inline-block;
						min-width: 46px;
						font-size: 1.7rem;
						font-weight: 500;
						line-height: (24/17);
						&::before, &::after { 
							position: absolute;
							top: 49px;
							width: 2px;
							height: 24px;
							content: '';
							opacity: 0;
						}
						&::before { 
							left: -14px;
							transform: rotate(-30deg);
						}
						&::after { 
							right: -14px;
							transform: rotate(30deg);
						}
					}
				}
				&.sightseen a { 
					color: $clr_purple;
					.txt { 
						&::before, &::after {  background: $clr_purple; }
					}
				}
				&.gourmet a { 
					color: $clr_sky;
					.txt { 
						&::before, &::after {  background: $clr_sky; }
					}
				}
				&.event a { 
					color: $clr_lime;
					.txt { 
						&::before, &::after {  background: $clr_lime; }
					}
				}
				&.experience a { 
					color: $clr_yellow;
					.txt { 
						&::before, &::after {  background: $clr_yellow; }
					}
				}
				&.souvenir a { 
					color: $clr_orange;
					.txt { 
						&::before, &::after {  background: $clr_orange; }
					}
				}
				&.hotel a { 
					color: $clr_cherry;
					.txt { 
						&::before, &::after {  background: $clr_cherry; }
					}
				}
				&.life a { 
					color: $clr_brown;
					.txt { 
						&::before, &::after {  background: $clr_brown; }
					}
				}
				a:hover { 
					.txt { 
						animation: catnav-hover-txt .45s ease-in;
						&::before, &::after { 
							animation: catnav-hover-line .45s;
							top: 3px;
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
@keyframes catnav-hover-line { 
	0% {  top: 49px; opacity: 0; }
	50% {  top: 49px; opacity: 1; }
	98% {  top: 1px; }
	100% {  top: 3px; }
}
@keyframes catnav-hover-txt { 
	0% {  top: 0; }
	98% {  top: -2px; }
	100% {  top: 0; }
}
/* #recom
-------------------------------------------------*/
#recom { 
	.inner { 
		padding: 35px 0 15px;
	}
	.tit { 
		margin-bottom: 16px;
		text-align: center;
		span { 
			padding-left: 40px;
			background: url(../img/common/ico_recom01.png) no-repeat 0 50%;
			background-size: 28px auto;
			font-size: 2rem;
			line-height: 1.5;
		}
	}
	.list { 
		.item { 
			padding: 0 5px;
			box-sizing: border-box;
			.pic { 
				margin-bottom: 9px;
			}
			.name { 
				margin-bottom: 4px;
				font-size: 1.5rem;
				font-weight: 500;
			}
			.txt { 
				margin-bottom: 11px;
				color: #8c8c8c;
			}
			.area { 
				display: inline-block;
				padding: 3px 5px;
				border: 1px solid $clr_purple;
				color: $clr_purple;
				font-size: 1.1rem;
				line-height: (12/11);
			}
		}
		.slick-arrow { 
			display: block;
			border: 0;
			overflow: hidden;
			text-indent: 200%;
			white-space: nowrap;
			position: absolute;
			top: 27%;
			width: 24px;
			height: 40px;
			background: $clr_pink no-repeat center;
			background-size: 5px auto;
			z-index: 2;
			&.slick-prev { 
				left: 0;
				background-image: url(../img/common/ico_arrow01_prev.png);
			}
			&.slick-next { 
				right: 0;
				background-image: url(../img/common/ico_arrow01_next.png);
			}
		}
	}
	@media all and (min-width: 600px) { 
		.inner { 
			padding: 51px 0 35px;
		}
		.tit { 
			margin-bottom: 41px;
			span { 
				padding-left: 63px;
				background-size: 48px auto;
				font-size: 3.6rem;
				letter-spacing: .14em;
			}
		}
		.list { 
			.item { 
				width: 300px;
				padding: 0 10px;
				.pic { 
					margin-bottom: 13px;
				}
				.name { 
					margin-bottom: 9px;
					font-size: 1.8rem;
				}
				.txt { 
					margin-bottom: 17px;
				}
				.area { 
					padding: 4px 12px;
					font-size: 1.2rem;
					line-height: (14/12);
				}
			}
			.slick-arrow { 
				top: 50%;
				width: 40px;
				height: 60px;
				margin-top: -30px;
			}
		}
	}
}
/* #category
-------------------------------------------------*/
#category { 
	margin-bottom: 15px;
	padding-bottom: 85px;
	background: url(../img/home/bg_category01.png) no-repeat 63% 100%;
	background-size: auto 71px;
	.inner { 
		padding-top: 20px;
	}
	.tit { 
		margin-bottom: 18px;
		span { 
			padding-left: 22px;
			background: #fff url(../img/common/ico_menu01_cat.png) no-repeat -2px 55%;
			background-size: 17px;
			font-size: 1.7rem;
			font-weight: 500;
			line-height: (24/17);
		}
	}
	.names { 
		.flickity-viewport { 
			padding-bottom: 20px;
		}
	}
	.name { 
		position: relative;
		width: 118px;
		height: 45px;
		margin-right: 5px;
		padding-top: 11px;
		border: 1px solid;
		background: #fff;
		box-sizing: border-box;
		text-align: center;
		@include trans($prop: background);
		&.sightseen {  border-color: $clr_purple; color: $clr_purple; }
		&.gourmet {  border-color: $clr_sky; color: $clr_sky; }
		&.event {  border-color: $clr_lime; color: $clr_lime; }
		&.experience {  border-color: $clr_yellow; color: $clr_yellow; }
		&.souvenir {  border-color: $clr_orange; color: $clr_orange; }
		&.hotel {  border-color: $clr_cherry; color: $clr_cherry; }
		&.life {  border-color: $clr_brown; color: $clr_brown; }
		span { 
			position: relative;
			padding-left: 26px;
		}
		.ico { 
			position: absolute;
			top: 50%;
			left: 0;
			width: 21px;
			margin-top: -7px;
			@include trans;
			&.is_white {  opacity: 0; }
		}
		&.is-nav-selected { 
			&.sightseen {  background: $clr_purple; color: #fff; }
			&.gourmet {  background: $clr_sky; color: #fff; }
			&.event {  background: $clr_lime; color: #fff; }
			&.experience {  background: $clr_yellow; color: #fff; }
			&.souvenir {  background: $clr_orange; color: #fff; }
			&.hotel {  background: $clr_cherry; color: #fff; }
			&.life {  background: $clr_brown; color: #fff; }
			.ico { 
				opacity: 0;
				&.is_white {  opacity: 1; }
			}
			&::after { 
				position: absolute;
				bottom: -7px;
				left: 50%;
				width: 0;
				height: 0;
				margin-left: -7px;
				content: '';
				border-style: solid;
				border-width: 7px 7px 0 7px;
				border-color: transparent;
			}
			&.sightseen::after {  border-top-color: $clr_purple; }
			&.gourmet::after {  border-top-color: $clr_sky; }
			&.event::after {  border-top-color: $clr_lime; }
			&.experience::after {  border-top-color: $clr_yellow; }
			&.souvenir::after {  border-top-color: $clr_orange; }
			&.hotel::after {  border-top-color: $clr_cherry; }
			&.life::after {  border-top-color: $clr_brown; }
		}
		
	}
	.list { 
		.item { 
			width: 100%;
		}
	}
	.btns { 
		.btn { 
			border-bottom: 1px solid #fff;
			a { 
				position: relative;
				display: block;
				padding: 13px 15px 12px;
				color: #fff;
				&::after { 
					position: absolute;
					top: 50%;
					right: 10px;
					width: 0;
					height: 0;
					content: '';
					margin-top: -4px;
					border-style: solid;
					border-width: 4px 0 4px 4px;
					border-color: transparent transparent transparent #fff;
				}
			}
		}
		&.sightseen .btn a {  background: $clr_purple; }
		&.gourmet .btn a {  background: $clr_sky; }
		&.event .btn a {  background: $clr_lime; }
		&.experience .btn a {  background: $clr_yellow; }
		&.souvenir .btn a {  background: $clr_orange; }
		&.hotel .btn a {  background: $clr_cherry; }
		&.life .btn a {  background: $clr_brown; }
	}
	@media all and (min-width: 600px) { 
		margin-bottom: 55px;
		padding-bottom: 0;
		background: url(../img/home/bg_category01.png) no-repeat 51% 94%;
		background-size: 1119px auto;
		.inner { 
			padding-top: 30px;
		}
		.tit { 
			position: relative;
			margin-bottom: 32px;
			span { 
				position: relative;
				padding-left: 30px;
				padding-right: 17px;
				background-position: 0 50%;
				background-size: 20px;
				font-size: 2.2rem;
				line-height: 1.5;
				z-index: 1;
			}
			&::before { 
				position: absolute;
				top: 17px;
				left: 0;
				width: 1180px;
				height: 1px;
				content: '';
				background: #dcdcdc;
				z-index: -1;
			}
		}
		.name { 
			width: 380px;
			height: 180px;
			margin-right: 0;
			padding-top: 129px;
			border: 0;
			background: #fff;
			font-size: 2.2rem;
			font-weight: 500;
			&.sightseen {  background: url(../img/home/bg_category02_sightseen.png) no-repeat 50% 50%; color: #333; }
			&.gourmet {  background: url(../img/home/bg_category02_gourmet.png) no-repeat 50% 50%; color: #333; }
			&.event {  background: url(../img/home/bg_category02_event.png) no-repeat 50% 50%; color: #333; }
			&.experience {  background: url(../img/home/bg_category02_experience.png) no-repeat 50% 50%; color: #333; }
			&.souvenir {  background: url(../img/home/bg_category02_souvenir.png) no-repeat 50% 50%; color: #333; }
			&.hotel {  background: url(../img/home/bg_category02_hotel.png) no-repeat 50% 50%; color: #333; }
			&.life {  background: url(../img/home/bg_category02_life.png) no-repeat 50% 50%; color: #333; }
			.ico { 
				position: absolute;
				top: 20px;
				left: 50%;
				width: 105px;
				margin-top: 0;
				margin-left: -52px;
				@include trans;
				&.is_white {  opacity: 0; }
			}
			&.is-nav-selected { 
				&.sightseen {  background: $clr_purple; color: #fff; }
				&.gourmet {  background: $clr_sky; color: #fff; }
				&.event {  background: $clr_lime; color: #fff; }
				&.experience {  background: $clr_yellow; color: #fff; }
				&.souvenir {  background: $clr_orange; color: #fff; }
				&.hotel {  background: $clr_cherry; color: #fff; }
				&.life {  background: $clr_brown; color: #fff; }
				.ico { 
					opacity: 0;
					&.is_white {  opacity: 1; }
				}
				&::after { 
					position: absolute;
					bottom: -7px;
					left: 50%;
					width: 0;
					height: 0;
					margin-left: -7px;
					content: '';
					border-style: solid;
					border-width: 7px 7px 0 7px;
					border-color: transparent;
				}
				&.sightseen::after {  border-top-color: $clr_purple; }
				&.gourmet::after {  border-top-color: $clr_sky; }
				&.event::after {  border-top-color: $clr_lime; }
				&.experience::after {  border-top-color: $clr_yellow; }
				&.souvenir::after {  border-top-color: $clr_orange; }
				&.hotel::after {  border-top-color: $clr_cherry; }
				&.life::after {  border-top-color: $clr_brown; }
			}

		}
		.list { 
			overflow: hidden;
			margin-right: -20px;
			.item { 
				position: relative;
				float: left;
				width: 380px;
				height: 180px;
				margin: 0 20px 20px 0;
				overflow: hidden;
				cursor: pointer;
			}
		}
		.btns { 
			position: absolute;
			top: 0;
			left: 0;
			width: 381px;
			height: 181px;
			opacity: 0;
			@include trans;
			.btn { 
				float: left;
				width: 127px;
				border-bottom: 0;
				a { 
					display: block;
					height: 60px;
					padding: 21px 0 0;
					border: 1px solid #fff;
					border-width: 0 1px 1px 0;
					box-sizing: border-box;
					font-weight: 500;
					text-align: center;
					@include trans;
					&::after { 
						display: none;
					}
				}
				&:nth-child(n+7) a { 
					border-width: 0 1px 0 0;
				}
			}
			&.sightseen .btn a { 
				background: rgba($clr_purple, .9);
				&:hover { 
					background: $clr_purple;
				}
			}
			&.gourmet .btn a { 
				background: rgba($clr_sky, .9);
				&:hover { 
					background: $clr_sky;
				}
			}
			&.event .btn a { 
				background: rgba($clr_lime, .9);
				&:hover { 
					background: $clr_lime;
				}
			}
			&.experience .btn a { 
				background: rgba($clr_yellow, .9);
				&:hover { 
					background: $clr_yellow;
				}
			}
			&.souvenir .btn a { 
				background: rgba($clr_orange, .9);
				&:hover { 
					background: $clr_orange;
				}
			}
			&.hotel .btn a { 
				background: rgba($clr_cherry, .9);
				&:hover { 
					background: $clr_cherry;
				}
			}
			&.life .btn a { 
				background: rgba($clr_brown, .9);
				&:hover { 
					background: $clr_brown;
				}
			}
		}
		.item:hover .btns { 
			opacity: 1;
		}
	}
}
/* #gallery
-------------------------------------------------*/
#gallery { 
	.list { 
		background: #000;
		overflow: hidden;
		.item { 
			position: relative;
			float: left;
			width: 33.3333%;
			height: 66.6666%;
			background: #fff;
			overflow: hidden;
			.name { 
				position: absolute;
				right: 5px;
				bottom: 6px;
				color: #fff;
				font-size: 1.2rem;
				font-weight: 500;
			}
			@media all and (max-width: 599px) { 
				&:nth-child(2),
				&:nth-child(4) { 
					width: 66.6666%;
				}
				&:nth-child(2) {  float: right; }
				&:nth-child(7) {  clear: both; }
				&:nth-child(n+10) {  display: none; }
				&:nth-child(1), &:nth-child(3), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10), { 
					.name {  display: none; }
				}
			}
		}
	}
	@media all and (min-width: 600px) { 
		.list { 
			position: relative;
			.item { 
				width: 16.6666%;
				&:nth-child(1) { 
					width: 33.3333%;
				}
				&:nth-child(7) { 
					float: right;
				}
				&:nth-child(8) { 
					float: right;
					width: 33.3333%;
				}
				&:nth-child(9) { 
					float: right;
				}
				&:nth-child(10) { 
					float: right;
				}
				&:nth-child(11) { 
					float: right;
				}
				&:nth-child(12) { 
					float: none;
					position: absolute;
					right: 0;
					bottom: 0;
					height: 33.3333%;
				}
				.name { 
					position: absolute;
					right: 15px;
					bottom: 11px;
					font-size: 1.4rem;
				}
			}
		}
	}
}
/* #event
-------------------------------------------------*/
#event { 
	background: #f2f6f8;
	.inner { 
		padding: 20px 10px 10px;
	}
	.tit { 
		margin-bottom: 13px;
		padding-top: 32px;
		background: url(../img/home/ico_event01.png) no-repeat 50% 0;
		background-size: 43px auto;
		font-size: 2rem;
		text-align: center;
	}
	.box { 
		padding: 18px 15px 25px;
		background: #fff;
	}
	@media all and (min-width: 600px) { 
		.inner { 
			padding: 40px 0 55px;
		}
		.tit { 
			margin-bottom: 29px;
			padding-top: 57px;
			background-size: 67px auto;
			font-size: 3.5rem;
			line-height: 1.4;
			letter-spacing: .14em;

		}
		.box { 
			padding: 46px 50px 55px;
		}
	}
	.monthly { 
		margin-bottom: 33px;
		.sub { 
			margin-bottom: 13px;
			padding-left: 23px;
			background: url(../img/home/ico_event02_monthly.png) no-repeat 0 50%;
			background-size: 16px;
			font-size: 1.6rem;
			font-weight: 500;
		}
		.nums { 
			width: 100%;
			margin-bottom: 13px;
			padding-bottom: 7px;
			letter-spacing: -.5em;
			white-space: nowrap;
			overflow-x: scroll;
			.num { 
				display: inline-block;
				width: 73px;
				height: 45px;
				padding-top: 11px;
				background: $clr_pink_l;
				box-sizing: border-box;
				color: $clr_cherry;
				font-weight: 700;
				text-align: center;
				letter-spacing: .04em;
				cursor: pointer;
				@include trans;
				&::after { 
					position: absolute;
					bottom: -7px;
					left: 50%;
					width: 0;
					height: 0;
					content: '';
					margin-left: -7px;
					border-style: solid;
					border-width: 7px 7px 0 7px;
					border-color: $clr_pink_l transparent transparent;
					opacity: 0;
					@include trans;
				}
				&.is_active { 
					position: relative;
					background: $clr_pink;
					color: #fff;
					&::after { 
						border-top-color: $clr_pink;
						opacity: 1;
					}
				}
			}
		}
		.contents { 
			position: relative;
			/*height: 70px;*/
			.content { 
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				z-index: -100;
				&.is_active { 
					z-index: auto;
				}
				.list { 
					.item { 
						padding: 0 5px;
						box-sizing: border-box;
						a { 
							position: relative;
							display: table;
							width: 100%;
							/*height: 70px;*/
							padding: 28px 10px 14px;
							border: 1px solid #333;
							box-sizing: border-box;
							.time { 
								position: absolute;
								top: 0;
								left: 0;
								padding: 4px 5px;
								background: $clr_lime;
								color: #fff;
								font-size: 1.1rem;
								text-align: center;
								line-height: (12/11);
							}
							.name { 
								display: table-cell;
								font-size: 1.4rem;
								font-weight: 500;
								vertical-align: middle;
							}
						}
					}
					.slick-arrow { 
						display: block;
						border: 0;
						overflow: hidden;
						text-indent: 200%;
						white-space: nowrap;
						position: absolute;
						top: 50%;
						width: 24px;
						height: 40px;
						background: $clr_pink no-repeat center;
						background-size: 5px auto;
						z-index: 2;
						&.slick-prev { 
							left: -15px;
							background-image: url(../img/common/ico_arrow01_prev.png);
						}
						&.slick-next { 
							right: -15px;
							background-image: url(../img/common/ico_arrow01_next.png);
						}
					}
				}
			}
		}
		@media all and (min-width: 600px) { 
			margin-bottom: 42px;
			.sub { 
				margin-bottom: 14px;
				padding-left: 34px;
				background-size: 23px;
				font-size: 2rem;
			}
			.nums { 
				margin-bottom: 18px;
				overflow-x: visible;
				.num { 
					width: 90px;
					height: 54px;
					padding-top: 14px;
					font-size: 1.6rem;
				}
			}
			.contents { 
				/*height: 80px;*/
				.content { 
					.list { 
						.item { 
							padding:0 8px;
							a { 
								/*height: 80px;*/
								@include trans;
								.time { 
									padding: 5px 15px;
									font-size: 1.2rem;
									line-height: (14/12);
								}
								&:hover { 
									color: $clr_pink;
									border-color: $clr_pink;
								}
							}
						}
						.slick-arrow { 
							width: 30px;
							height: 50px;
							&.slick-prev { 
								left: 0;
							}
							&.slick-next { 
								right: 0;
							}
						}
					}
				}
			}
		}
	}
	.attend { 
		.sub { 
			margin-bottom: 13px;
			padding-left: 23px;
			background: url(../img/home/ico_event02_attend.png) no-repeat 0 50%;
			background-size: 16px;
			font-size: 1.6rem;
			font-weight: 500;
		}
		.list { 
			margin-bottom: 21px;
			.item { 
				padding: 0 5px;
				a { 
					position: relative;
					.pic { 
						margin-bottom: 12px;
					}
					.time { 
						position: absolute;
						top: 0;
						left: 0;
						padding: 4px 5px;
						background: $clr_lime;
						color: #fff;
						font-size: 1.1rem;
						text-align: center;
						line-height: (12/11);
					}
					.name { 
						font-size: 1.5rem;
						font-weight: 500;
						line-height: (44/30);
					}
				}
			}
			.slick-arrow { 
				display: block;
				border: 0;
				overflow: hidden;
				text-indent: 200%;
				white-space: nowrap;
				position: absolute;
				top: 50%;
				width: 24px;
				height: 40px;
				background: $clr_pink no-repeat center;
				background-size: 5px auto;
				z-index: 2;
				&.slick-prev { 
					left: -15px;
					background-image: url(../img/common/ico_arrow01_prev.png);
				}
				&.slick-next { 
					right: -15px;
					background-image: url(../img/common/ico_arrow01_next.png);
				}
			}
		}
		@media all and (min-width: 600px) { 
			.sub { 
				margin-bottom: 14px;
				padding-left: 34px;
				background-size: 23px;
				font-size: 2rem;
			}
			.list { 
				margin-bottom: 24px;
				.item { 
					padding: 0 11px;
					a { 
						.pic { 
							margin-bottom: 14px;
						}
						.time { 
							padding: 5px 15px;
							font-size: 1.2rem;
							line-height: (14/12);
						}
						.name { 
							font-size: 1.6rem;
							line-height: 1.5;
						}
					}
				}
				.slick-arrow { 
					width: 30px;
					height: 50px;
					&.slick-prev { 
						left: 0;
					}
					&.slick-next { 
						right: 0;
					}
				}
			}
		}
	}
}
/* #pickup
-------------------------------------------------*/
#pickup { 
	background: url(../img/home/bg_pickup01.png) 50% 0;
	background-size: 54px;
	.inner { 
		padding: 20px 10px 25px;
	}
	.tit { 
		margin-bottom: 13px;
		padding-top: 32px;
		background: url(../img/home/ico_pickup01.png) no-repeat 50% 0;
		background-size: 50px auto;
		font-size: 2rem;
		text-align: center;
	}
	.list { 
		padding-bottom: 15px;
		.item { 
			margin-bottom: 10px;
			background: #fff;
			a { 
				position: relative;
				padding: 15px 15px 8px;
				border: 1px solid $clr_pink;
				.pic { 
					position: absolute;
					top: 15px;
					left: 15px;
					width: 107px;
				}
				.meta { 
					margin-bottom: 7px;
					padding-left: 122px;
					font-size: 1.1rem;
					line-height: (12/11);
					overflow: hidden;
					.cat, .area { 
						float: left;
						padding: 3px 9px;
						border: 1px solid;
						text-align: center;
					}
					.area { 
						color: #fff;
					}
					&.sightseen { 
						.cat, .area {  border-color: $clr_purple; }
						.cat {  color: $clr_purple; }
						.area {  background: $clr_purple; }
					}
					&.gourmet { 
						.cat, .area {  border-color: $clr_sky; }
						.cat {  color: $clr_sky; }
						.area {  background: $clr_sky; }
					}
					&.event { 
						.cat, .area {  border-color: $clr_lime; }
						.cat {  color: $clr_lime; }
						.area {  background: $clr_lime; }
					}
					&.experience { 
						.cat, .area {  border-color: $clr_yellow; }
						.cat {  color: $clr_yellow; }
						.area {  background: $clr_yellow; }
					}
					&.souvenir { 
						.cat, .area {  border-color: $clr_orange; }
						.cat {  color: $clr_orange; }
						.area {  background: $clr_orange; }
					}
					&.hotel { 
						.cat, .area {  border-color: $clr_cherry; }
						.cat {  color: $clr_cherry; }
						.area {  background: $clr_cherry; }
					}
					&.life { 
						.cat, .area {  border-color: $clr_brown; }
						.cat {  color: $clr_brown; }
						.area {  background: $clr_brown; }
					}
				}
				.sub { 
					min-height: 30px;
					margin-bottom: 10px;
					padding-left: 122px;
					font-size: 1.4rem;
					font-weight: 500;
				}
				.date { 
					padding-top: 5px;
					border-top: 1px solid #e7e7e7;
					color: #8c8c8c;
					text-align: right;
				}
			}
		}
	}
	@media all and (min-width: 600px) { 
		.inner { 
			padding: 40px 0 50px;
		}
		.tit { 
			margin-bottom: 29px;
			padding-top: 57px;
			background-size: 78px auto;
			font-size: 3.5rem;
			letter-spacing: .14em;
			line-height: 1.4;
		}
		.list { 
			margin-right: -20px;
			padding-bottom: 30px;
			overflow: hidden;
			.item { 
				float: left;
				width: 580px;
				margin: 0 20px 20px 0;
				a { 
					min-height: 160px;
					padding: 19px;
					.pic { 
						top: 19px;
						left: 19px;
						width: 225px;
					}
					.meta { 
						margin-bottom: 13px;
						padding-left: 255px;
						font-size: 1.2rem;
						line-height: 1.5;
						.cat, .area { 
							padding: 2px 15px;
						}
					}
					.sub { 
						min-height: 30px;
						margin-bottom: 20px;
						padding-left: 255px;
						font-size: 1.7rem;
						line-height: (25/17);
					}
					.date { 
						width: 285px;
						margin: 0 0 0 auto;
						padding-top: 10px;
						font-size: 1.5rem;
						line-height: 1.4;
					}
				}
			}
		}
	}
}
/* #gourmet
-------------------------------------------------*/
#gourmet { 
	.inner { 
		padding-top: 20px;
		padding-bottom: 25px;
	}
	.tit { 
		margin-bottom: 13px;
		padding-top: 32px;
		background: url(../img/home/ico_gourmet01.png) no-repeat 50% 0;
		background-size: 50px auto;
		font-size: 2rem;
		text-align: center;
	}
	.list { 
		margin: 0 -5px;
		padding-bottom: 6px;
		overflow: hidden;
		.item { 
			float: left;
			width: 50%;
			margin-bottom: 19px;
			padding: 0 5px;
			box-sizing: border-box;
			.pic { 
				margin-bottom: 7px;
			}
			.sub { 
				margin-bottom: 8px;
				font-size: 1.4rem;
				font-weight: 500;
				line-height: (40/28);
			}
			.area { 
				display: inline-block;
				padding: 3px 12px;
				color: $clr_sky;
				border: 1px solid $clr_sky;
				font-size: 1.1rem;
				line-height: (12/11);
			}
		}
	}
	@media all and (min-width: 600px) { 
		.inner { 
			padding: 40px 0 50px;
		}
		.tit { 
			margin-bottom: 29px;
			padding-top: 57px;
			background-size: 96px auto;
			font-size: 3.5rem;
			letter-spacing: .14em;
			line-height: 1.4;
		}
		.list { 
			margin: 0 -10px;
			padding-bottom: 40px;
			.item { 
				width: 300px;
				margin-bottom: 0;
				padding: 0 10px;
				.pic { 
					margin-bottom: 14px;
				}
				.sub { 
					margin-bottom: 12px;
					font-size: 1.6rem;
					line-height: 1.5;
				}
				.area { 
					padding: 2px 15px;
					font-size: 1.2rem;
					line-height: 1.5;
				}
			}
		}
	}
}
/* #info
-------------------------------------------------*/
#info { 
	background: url(../img/home/bg_info01.png) no-repeat 50% 50%;
	background-size: cover;
	.inner { 
		padding-top: 15px;
		padding-bottom: 24px;
	}
	.box { 
		.news { 
			position: relative;
			margin-bottom: 25px;
			padding: 22px 20px 23px;
			background: rgba(#fff, .9);
			.tit { 
				padding-bottom: 15px;
				border-bottom: 1px solid #dcdcdc;
				font-size: 1.9rem;
				line-height: (30/19);
			}
			.list { 
				.item { 
					a { 
						padding: 12px 0;
						border-bottom: 1px solid #dcdcdc;
						.date { 
							margin-bottom: 3px;
							color: #8c8c8c;
							font-size: 1.2rem;
							line-height: 1.5;
						}
						.sub { 
							color: #000;
							font-size: 1.3rem;
						}
					}
				}
			}
			.btn { 
				position: absolute;
				top: 22px;
				right: 20px;
				width: 120px;
				a { 
					position: relative;
					display: block;
					height: 30px;
					padding-top: 6px;
					padding-right: 5px;
					border: 1px solid #4d4d4d;
					background: #4d4d4d;
					box-sizing: border-box;
					color: #fff;
					font-size: 1.1rem;
					font-weight: 500;
					text-align: center;
					&::after { 
						position: absolute;
						top: 50%;
						right: 10px;
						width: 0;
						height: 0;
						content: '';
						margin-top: -4px;
						border-style: solid;
						border-width: 4px 0 4px 4px;
						border-color: transparent transparent transparent #fff;
					}
				}
			}
		}
	}
	@media all and (min-width: 600px) { 
		.inner { 
			padding-top: 70px;
			padding-bottom: 60px;
			overflow: hidden;
		}
		.facebook { 
			float: left;
			width: 300px;
			height: 424px;
			margin-right: 30px;
		}
		.box { 
			float: left;
			width: 310px;
			.news { 
				margin-bottom: 30px;
				padding: 33px 30px 30px;
				.tit { 
					padding-bottom: 14px;
					border-bottom: 1px solid #dcdcdc;
					font-size: 2.3rem;
					line-height: (30/23);
				}
				.list { 
					margin-bottom: 25px;
					.item { 
						a { 
							padding: 27px 0 32px;
							.date { 
								margin-bottom: 6px;
								font-size: 1.6rem;
							}
							.sub { 
								font-size: 1.5rem;
								line-height: (26/15);
							}
						}
					}
				}
				.btn { 
					position: static;
					width: 150px;
					margin: 0 auto;
					a { 
						height: 35px;
						padding-right: 9px;
						font-size: 1.3rem;
						@include trans;
						&::after { 
							right: 10px;
						}
						&:hover { 
							color: #444;
							background: #fff;
							&::after { 
								border-left-color: #444;
							}
						}
					}
				}
			}
			.about { 
				width: 270px;
			}
		}
	}
}