@charset "UTF-8";
/*=============================================================
 home トップページ
=============================================================*/
/* #catsNav
-------------------------------------------------*/
@media all and (min-width: 600px) {  #catsNav {  border-bottom: 1px solid #ededed; overflow: hidden; }
  #catsNav .list {  position: relative; letter-spacing: -.5em; }
  #catsNav .list.temporary {  width: 400px; }
  #catsNav .list.temporary .item {  width: 50%; }
  #catsNav .list::after {  position: absolute; top: 34px; left: 0; content: ''; width: 1px; height: 30px; background: #dcdcdc; }
  #catsNav .list .item {  display: inline-block; width: 14.28571%; box-sizing: border-box; letter-spacing: .04em; }
  #catsNav .list .item a {  position: relative; display: block; height: 82px; padding: 17px 0 0; background: #fff; text-align: center; }
  #catsNav .list .item a::after {  position: absolute; top: 34px; right: 0; content: ''; width: 1px; height: 30px; background: #dcdcdc; }
  #catsNav .list .item a .ico {  display: block; margin-bottom: 7px; }
  #catsNav .list .item a .ico img {  display: block; width: 42px; margin: 0 auto; }
  #catsNav .list .item a .txt {  position: relative; display: inline-block; min-width: 46px; font-size: 1.7rem; font-weight: 500; line-height: 1.41176; }
  #catsNav .list .item a .txt::before, #catsNav .list .item a .txt::after {  position: absolute; top: 49px; width: 2px; height: 24px; content: ''; opacity: 0; }
  #catsNav .list .item a .txt::before {  left: -14px; transform: rotate(-30deg); }
  #catsNav .list .item a .txt::after {  right: -14px; transform: rotate(30deg); }
  #catsNav .list .item.sightseen a {  color: #9e4fbf; }
  #catsNav .list .item.sightseen a .txt::before, #catsNav .list .item.sightseen a .txt::after {  background: #9e4fbf; }
  #catsNav .list .item.gourmet a {  color: #2198c4; }
  #catsNav .list .item.gourmet a .txt::before, #catsNav .list .item.gourmet a .txt::after {  background: #2198c4; }
  #catsNav .list .item.event a {  color: #71c72b; }
  #catsNav .list .item.event a .txt::before, #catsNav .list .item.event a .txt::after {  background: #71c72b; }
  #catsNav .list .item.experience a {  color: #e6b33a; }
  #catsNav .list .item.experience a .txt::before, #catsNav .list .item.experience a .txt::after {  background: #e6b33a; }
  #catsNav .list .item.souvenir a {  color: #ef7e3a; }
  #catsNav .list .item.souvenir a .txt::before, #catsNav .list .item.souvenir a .txt::after {  background: #ef7e3a; }
  #catsNav .list .item.hotel a {  color: #e54c80; }
  #catsNav .list .item.hotel a .txt::before, #catsNav .list .item.hotel a .txt::after {  background: #e54c80; }
  #catsNav .list .item.life a {  color: #925848; }
  #catsNav .list .item.life a .txt::before, #catsNav .list .item.life a .txt::after {  background: #925848; }
  #catsNav .list .item a:hover .txt {  animation: catnav-hover-txt .45s ease-in; }
  #catsNav .list .item a:hover .txt::before, #catsNav .list .item a:hover .txt::after {  animation: catnav-hover-line .45s; top: 3px; opacity: 1; } }

@keyframes catnav-hover-line {  0% {  top: 49px;
    opacity: 0; }
  50% {  top: 49px;
    opacity: 1; }
  98% {  top: 1px; }
  100% {  top: 3px; } }

@keyframes catnav-hover-txt {  0% {  top: 0; }
  98% {  top: -2px; }
  100% {  top: 0; } }

/* #recom
-------------------------------------------------*/
#recom .inner {  padding: 35px 0 15px; }

#recom .tit {  margin-bottom: 16px; text-align: center; }

#recom .tit span {  padding-left: 40px; background: url(../img/common/ico_recom01.png) no-repeat 0 50%; background-size: 28px auto; font-size: 2rem; line-height: 1.5; }

#recom .list .item {  padding: 0 5px; box-sizing: border-box; }

#recom .list .item .pic {  margin-bottom: 9px; }

#recom .list .item .name {  margin-bottom: 4px; font-size: 1.5rem; font-weight: 500; }

#recom .list .item .txt {  margin-bottom: 11px; color: #8c8c8c; }

#recom .list .item .area {  display: inline-block; padding: 3px 5px; border: 1px solid #9e4fbf; color: #9e4fbf; font-size: 1.1rem; line-height: 1.09091; }

#recom .list .slick-arrow {  display: block; border: 0; overflow: hidden; text-indent: 200%; white-space: nowrap; position: absolute; top: 27%; width: 24px; height: 40px; background: #fd6978 no-repeat center; background-size: 5px auto; z-index: 2; }

#recom .list .slick-arrow.slick-prev {  left: 0; background-image: url(../img/common/ico_arrow01_prev.png); }

#recom .list .slick-arrow.slick-next {  right: 0; background-image: url(../img/common/ico_arrow01_next.png); }

@media all and (min-width: 600px) {  #recom .inner {  padding: 51px 0 35px; }
  #recom .tit {  margin-bottom: 41px; }
  #recom .tit span {  padding-left: 63px; background-size: 48px auto; font-size: 3.6rem; letter-spacing: .14em; }
  #recom .list .item {  width: 300px; padding: 0 10px; }
  #recom .list .item .pic {  margin-bottom: 13px; }
  #recom .list .item .name {  margin-bottom: 9px; font-size: 1.8rem; }
  #recom .list .item .txt {  margin-bottom: 17px; }
  #recom .list .item .area {  padding: 4px 12px; font-size: 1.2rem; line-height: 1.16667; }
  #recom .list .slick-arrow {  top: 50%; width: 40px; height: 60px; margin-top: -30px; } }

/* #category
-------------------------------------------------*/
#category {  margin-bottom: 15px; padding-bottom: 85px; background: url(../img/home/bg_category01.png) no-repeat 63% 100%; background-size: auto 71px; }

#category .inner {  padding-top: 20px; }

#category .tit {  margin-bottom: 18px; }

#category .tit span {  padding-left: 22px; background: #fff url(../img/common/ico_menu01_cat.png) no-repeat -2px 55%; background-size: 17px; font-size: 1.7rem; font-weight: 500; line-height: 1.41176; }

#category .names .flickity-viewport {  padding-bottom: 20px; }

#category .name {  position: relative; width: 118px; height: 45px; margin-right: 5px; padding-top: 11px; border: 1px solid; background: #fff; box-sizing: border-box; text-align: center; transition: background 0.4s ease-in-out; }

#category .name.sightseen {  border-color: #9e4fbf; color: #9e4fbf; }

#category .name.gourmet {  border-color: #2198c4; color: #2198c4; }

#category .name.event {  border-color: #71c72b; color: #71c72b; }

#category .name.experience {  border-color: #e6b33a; color: #e6b33a; }

#category .name.souvenir {  border-color: #ef7e3a; color: #ef7e3a; }

#category .name.hotel {  border-color: #e54c80; color: #e54c80; }

#category .name.life {  border-color: #925848; color: #925848; }

#category .name span {  position: relative; padding-left: 26px; }

#category .name .ico {  position: absolute; top: 50%; left: 0; width: 21px; margin-top: -7px; transition: all 0.4s ease-in-out; }

#category .name .ico.is_white {  opacity: 0; }

#category .name.is-nav-selected.sightseen {  background: #9e4fbf; color: #fff; }

#category .name.is-nav-selected.gourmet {  background: #2198c4; color: #fff; }

#category .name.is-nav-selected.event {  background: #71c72b; color: #fff; }

#category .name.is-nav-selected.experience {  background: #e6b33a; color: #fff; }

#category .name.is-nav-selected.souvenir {  background: #ef7e3a; color: #fff; }

#category .name.is-nav-selected.hotel {  background: #e54c80; color: #fff; }

#category .name.is-nav-selected.life {  background: #925848; color: #fff; }

#category .name.is-nav-selected .ico {  opacity: 0; }

#category .name.is-nav-selected .ico.is_white {  opacity: 1; }

#category .name.is-nav-selected::after {  position: absolute; bottom: -7px; left: 50%; width: 0; height: 0; margin-left: -7px; content: ''; border-style: solid; border-width: 7px 7px 0 7px; border-color: transparent; }

#category .name.is-nav-selected.sightseen::after {  border-top-color: #9e4fbf; }

#category .name.is-nav-selected.gourmet::after {  border-top-color: #2198c4; }

#category .name.is-nav-selected.event::after {  border-top-color: #71c72b; }

#category .name.is-nav-selected.experience::after {  border-top-color: #e6b33a; }

#category .name.is-nav-selected.souvenir::after {  border-top-color: #ef7e3a; }

#category .name.is-nav-selected.hotel::after {  border-top-color: #e54c80; }

#category .name.is-nav-selected.life::after {  border-top-color: #925848; }

#category .list .item {  width: 100%; }

#category .btns .btn {  border-bottom: 1px solid #fff; }

#category .btns .btn a {  position: relative; display: block; padding: 13px 15px 12px; color: #fff; }

#category .btns .btn a::after {  position: absolute; top: 50%; right: 10px; width: 0; height: 0; content: ''; margin-top: -4px; border-style: solid; border-width: 4px 0 4px 4px; border-color: transparent transparent transparent #fff; }

#category .btns.sightseen .btn a {  background: #9e4fbf; }

#category .btns.gourmet .btn a {  background: #2198c4; }

#category .btns.event .btn a {  background: #71c72b; }

#category .btns.experience .btn a {  background: #e6b33a; }

#category .btns.souvenir .btn a {  background: #ef7e3a; }

#category .btns.hotel .btn a {  background: #e54c80; }

#category .btns.life .btn a {  background: #925848; }

@media all and (min-width: 600px) {  #category {  margin-bottom: 55px; padding-bottom: 0; background: url(../img/home/bg_category01.png) no-repeat 51% 94%; background-size: 1119px auto; }
  #category .inner {  padding-top: 30px; }
  #category .tit {  position: relative; margin-bottom: 32px; }
  #category .tit span {  position: relative; padding-left: 30px; padding-right: 17px; background-position: 0 50%; background-size: 20px; font-size: 2.2rem; line-height: 1.5; z-index: 1; }
  #category .tit::before {  position: absolute; top: 17px; left: 0; width: 1180px; height: 1px; content: ''; background: #dcdcdc; z-index: -1; }
  #category .name {  width: 380px; height: 180px; margin-right: 0; padding-top: 129px; border: 0; background: #fff; font-size: 2.2rem; font-weight: 500; }
  #category .name.sightseen {  background: url(../img/home/bg_category02_sightseen.png) no-repeat 50% 50%; color: #333; }
  #category .name.gourmet {  background: url(../img/home/bg_category02_gourmet.png) no-repeat 50% 50%; color: #333; }
  #category .name.event {  background: url(../img/home/bg_category02_event.png) no-repeat 50% 50%; color: #333; }
  #category .name.experience {  background: url(../img/home/bg_category02_experience.png) no-repeat 50% 50%; color: #333; }
  #category .name.souvenir {  background: url(../img/home/bg_category02_souvenir.png) no-repeat 50% 50%; color: #333; }
  #category .name.hotel {  background: url(../img/home/bg_category02_hotel.png) no-repeat 50% 50%; color: #333; }
  #category .name.life {  background: url(../img/home/bg_category02_life.png) no-repeat 50% 50%; color: #333; }
  #category .name .ico {  position: absolute; top: 20px; left: 50%; width: 105px; margin-top: 0; margin-left: -52px; transition: all 0.4s ease-in-out; }
  #category .name .ico.is_white {  opacity: 0; }
  #category .name.is-nav-selected.sightseen {  background: #9e4fbf; color: #fff; }
  #category .name.is-nav-selected.gourmet {  background: #2198c4; color: #fff; }
  #category .name.is-nav-selected.event {  background: #71c72b; color: #fff; }
  #category .name.is-nav-selected.experience {  background: #e6b33a; color: #fff; }
  #category .name.is-nav-selected.souvenir {  background: #ef7e3a; color: #fff; }
  #category .name.is-nav-selected.hotel {  background: #e54c80; color: #fff; }
  #category .name.is-nav-selected.life {  background: #925848; color: #fff; }
  #category .name.is-nav-selected .ico {  opacity: 0; }
  #category .name.is-nav-selected .ico.is_white {  opacity: 1; }
  #category .name.is-nav-selected::after {  position: absolute; bottom: -7px; left: 50%; width: 0; height: 0; margin-left: -7px; content: ''; border-style: solid; border-width: 7px 7px 0 7px; border-color: transparent; }
  #category .name.is-nav-selected.sightseen::after {  border-top-color: #9e4fbf; }
  #category .name.is-nav-selected.gourmet::after {  border-top-color: #2198c4; }
  #category .name.is-nav-selected.event::after {  border-top-color: #71c72b; }
  #category .name.is-nav-selected.experience::after {  border-top-color: #e6b33a; }
  #category .name.is-nav-selected.souvenir::after {  border-top-color: #ef7e3a; }
  #category .name.is-nav-selected.hotel::after {  border-top-color: #e54c80; }
  #category .name.is-nav-selected.life::after {  border-top-color: #925848; }
  #category .list {  overflow: hidden; margin-right: -20px; }
  #category .list .item {  position: relative; float: left; width: 380px; height: 180px; margin: 0 20px 20px 0; overflow: hidden; cursor: pointer; }
  #category .btns {  position: absolute; top: 0; left: 0; width: 381px; height: 181px; opacity: 0; transition: all 0.4s ease-in-out; }
  #category .btns .btn {  float: left; width: 127px; border-bottom: 0; }
  #category .btns .btn a {  display: block; height: 60px; padding: 21px 0 0; border: 1px solid #fff; border-width: 0 1px 1px 0; box-sizing: border-box; font-weight: 500; text-align: center; transition: all 0.4s ease-in-out; }
  #category .btns .btn a::after {  display: none; }
  #category .btns .btn:nth-child(n+7) a {  border-width: 0 1px 0 0; }
  #category .btns.sightseen .btn a {  background: rgba(158, 79, 191, 0.9); }
  #category .btns.sightseen .btn a:hover {  background: #9e4fbf; }
  #category .btns.gourmet .btn a {  background: rgba(33, 152, 196, 0.9); }
  #category .btns.gourmet .btn a:hover {  background: #2198c4; }
  #category .btns.event .btn a {  background: rgba(113, 199, 43, 0.9); }
  #category .btns.event .btn a:hover {  background: #71c72b; }
  #category .btns.experience .btn a {  background: rgba(230, 179, 58, 0.9); }
  #category .btns.experience .btn a:hover {  background: #e6b33a; }
  #category .btns.souvenir .btn a {  background: rgba(239, 126, 58, 0.9); }
  #category .btns.souvenir .btn a:hover {  background: #ef7e3a; }
  #category .btns.hotel .btn a {  background: rgba(229, 76, 128, 0.9); }
  #category .btns.hotel .btn a:hover {  background: #e54c80; }
  #category .btns.life .btn a {  background: rgba(146, 88, 72, 0.9); }
  #category .btns.life .btn a:hover {  background: #925848; }
  #category .item:hover .btns {  opacity: 1; } }

/* #gallery
-------------------------------------------------*/
#gallery .list {  background: #000; overflow: hidden; }

#gallery .list .item {  position: relative; float: left; width: 33.3333%; height: 66.6666%; background: #fff; overflow: hidden; }

#gallery .list .item .name {  position: absolute; right: 5px; bottom: 6px; color: #fff; font-size: 1.2rem; font-weight: 500; }

@media all and (max-width: 599px) {  #gallery .list .item:nth-child(2), #gallery .list .item:nth-child(4) {  width: 66.6666%; }
  #gallery .list .item:nth-child(2) {  float: right; }
  #gallery .list .item:nth-child(7) {  clear: both; }
  #gallery .list .item:nth-child(n+10) {  display: none; }
  #gallery .list .item:nth-child(1) .name, #gallery .list .item:nth-child(3) .name, #gallery .list .item:nth-child(5) .name, #gallery .list .item:nth-child(6) .name, #gallery .list .item:nth-child(7) .name, #gallery .list .item:nth-child(8) .name, #gallery .list .item:nth-child(9) .name, #gallery .list .item:nth-child(10) .name {  display: none; } }

@media all and (min-width: 600px) {  #gallery .list {  position: relative; }
  #gallery .list .item {  width: 16.6666%; }
  #gallery .list .item:nth-child(1) {  width: 33.3333%; }
  #gallery .list .item:nth-child(7) {  float: right; }
  #gallery .list .item:nth-child(8) {  float: right; width: 33.3333%; }
  #gallery .list .item:nth-child(9) {  float: right; }
  #gallery .list .item:nth-child(10) {  float: right; }
  #gallery .list .item:nth-child(11) {  float: right; }
  #gallery .list .item:nth-child(12) {  float: none; position: absolute; right: 0; bottom: 0; height: 33.3333%; }
  #gallery .list .item .name {  position: absolute; right: 15px; bottom: 11px; font-size: 1.4rem; } }

/* #event
-------------------------------------------------*/
#event {  background: #f2f6f8; }

#event .inner {  padding: 20px 10px 10px; }

#event .tit {  margin-bottom: 13px; padding-top: 32px; background: url(../img/home/ico_event01.png) no-repeat 50% 0; background-size: 43px auto; font-size: 2rem; text-align: center; }

#event .box {  padding: 18px 15px 25px; background: #fff; }

@media all and (min-width: 600px) {  #event .inner {  padding: 40px 0 55px; }
  #event .tit {  margin-bottom: 29px; padding-top: 57px; background-size: 67px auto; font-size: 3.5rem; line-height: 1.4; letter-spacing: .14em; }
  #event .box {  padding: 46px 50px 55px; } }

#event .monthly {  margin-bottom: 33px; }

#event .monthly .sub {  margin-bottom: 13px; padding-left: 23px; background: url(../img/home/ico_event02_monthly.png) no-repeat 0 50%; background-size: 16px; font-size: 1.6rem; font-weight: 500; }

#event .monthly .nums {  width: 100%; margin-bottom: 13px; padding-bottom: 7px; letter-spacing: -.5em; white-space: nowrap; overflow-x: scroll; }

#event .monthly .nums .num {  display: inline-block; width: 73px; height: 45px; padding-top: 11px; background: #ffe7f0; box-sizing: border-box; color: #e54c80; font-weight: 700; text-align: center; letter-spacing: .04em; cursor: pointer; transition: all 0.4s ease-in-out; }

#event .monthly .nums .num::after {  position: absolute; bottom: -7px; left: 50%; width: 0; height: 0; content: ''; margin-left: -7px; border-style: solid; border-width: 7px 7px 0 7px; border-color: #ffe7f0 transparent transparent; opacity: 0; transition: all 0.4s ease-in-out; }

#event .monthly .nums .num.is_active {  position: relative; background: #fd6978; color: #fff; }

#event .monthly .nums .num.is_active::after {  border-top-color: #fd6978; opacity: 1; }

#event .monthly .contents {  position: relative; /*height: 70px;*/ }

#event .monthly .contents .content {  position: absolute; top: 0; left: 0; width: 100%; z-index: -100; }

#event .monthly .contents .content.is_active {  z-index: auto; }

#event .monthly .contents .content .list .item {  padding: 0 5px; box-sizing: border-box; }

#event .monthly .contents .content .list .item a {  position: relative; display: table; width: 100%; /*height: 70px;*/ padding: 28px 10px 14px; border: 1px solid #333; box-sizing: border-box; }

#event .monthly .contents .content .list .item a .time {  position: absolute; top: 0; left: 0; padding: 4px 5px; background: #71c72b; color: #fff; font-size: 1.1rem; text-align: center; line-height: 1.09091; }

#event .monthly .contents .content .list .item a .name {  display: table-cell; font-size: 1.4rem; font-weight: 500; vertical-align: middle; }

#event .monthly .contents .content .list .slick-arrow {  display: block; border: 0; overflow: hidden; text-indent: 200%; white-space: nowrap; position: absolute; top: 50%; width: 24px; height: 40px; background: #fd6978 no-repeat center; background-size: 5px auto; z-index: 2; }

#event .monthly .contents .content .list .slick-arrow.slick-prev {  left: -15px; background-image: url(../img/common/ico_arrow01_prev.png); }

#event .monthly .contents .content .list .slick-arrow.slick-next {  right: -15px; background-image: url(../img/common/ico_arrow01_next.png); }

@media all and (min-width: 600px) {  #event .monthly {  margin-bottom: 42px; }
  #event .monthly .sub {  margin-bottom: 14px; padding-left: 34px; background-size: 23px; font-size: 2rem; }
  #event .monthly .nums {  margin-bottom: 18px; overflow-x: visible; }
  #event .monthly .nums .num {  width: 90px; height: 54px; padding-top: 14px; font-size: 1.6rem; }
  #event .monthly .contents {  /*height: 80px;*/ }
  #event .monthly .contents .content .list .item {  padding: 0 8px; }
  #event .monthly .contents .content .list .item a {  /*height: 80px;*/ transition: all 0.4s ease-in-out; }
  #event .monthly .contents .content .list .item a .time {  padding: 5px 15px; font-size: 1.2rem; line-height: 1.16667; }
  #event .monthly .contents .content .list .item a:hover {  color: #fd6978; border-color: #fd6978; }
  #event .monthly .contents .content .list .slick-arrow {  width: 30px; height: 50px; }
  #event .monthly .contents .content .list .slick-arrow.slick-prev {  left: 0; }
  #event .monthly .contents .content .list .slick-arrow.slick-next {  right: 0; } }

#event .attend .sub {  margin-bottom: 13px; padding-left: 23px; background: url(../img/home/ico_event02_attend.png) no-repeat 0 50%; background-size: 16px; font-size: 1.6rem; font-weight: 500; }

#event .attend .list {  margin-bottom: 21px; }

#event .attend .list .item {  padding: 0 5px; }

#event .attend .list .item a {  position: relative; }

#event .attend .list .item a .pic {  margin-bottom: 12px; }

#event .attend .list .item a .time {  position: absolute; top: 0; left: 0; padding: 4px 5px; background: #71c72b; color: #fff; font-size: 1.1rem; text-align: center; line-height: 1.09091; }

#event .attend .list .item a .name {  font-size: 1.5rem; font-weight: 500; line-height: 1.46667; }

#event .attend .list .slick-arrow {  display: block; border: 0; overflow: hidden; text-indent: 200%; white-space: nowrap; position: absolute; top: 50%; width: 24px; height: 40px; background: #fd6978 no-repeat center; background-size: 5px auto; z-index: 2; }

#event .attend .list .slick-arrow.slick-prev {  left: -15px; background-image: url(../img/common/ico_arrow01_prev.png); }

#event .attend .list .slick-arrow.slick-next {  right: -15px; background-image: url(../img/common/ico_arrow01_next.png); }

@media all and (min-width: 600px) {  #event .attend .sub {  margin-bottom: 14px; padding-left: 34px; background-size: 23px; font-size: 2rem; }
  #event .attend .list {  margin-bottom: 24px; }
  #event .attend .list .item {  padding: 0 11px; }
  #event .attend .list .item a .pic {  margin-bottom: 14px; }
  #event .attend .list .item a .time {  padding: 5px 15px; font-size: 1.2rem; line-height: 1.16667; }
  #event .attend .list .item a .name {  font-size: 1.6rem; line-height: 1.5; }
  #event .attend .list .slick-arrow {  width: 30px; height: 50px; }
  #event .attend .list .slick-arrow.slick-prev {  left: 0; }
  #event .attend .list .slick-arrow.slick-next {  right: 0; } }

/* #pickup
-------------------------------------------------*/
#pickup {  background: url(../img/home/bg_pickup01.png) 50% 0; background-size: 54px; }

#pickup .inner {  padding: 20px 10px 25px; }

#pickup .tit {  margin-bottom: 13px; padding-top: 32px; background: url(../img/home/ico_pickup01.png) no-repeat 50% 0; background-size: 50px auto; font-size: 2rem; text-align: center; }

#pickup .list {  padding-bottom: 15px; }

#pickup .list .item {  margin-bottom: 10px; background: #fff; }

#pickup .list .item a {  position: relative; padding: 15px 15px 8px; border: 1px solid #fd6978; }

#pickup .list .item a .pic {  position: absolute; top: 15px; left: 15px; width: 107px; }

#pickup .list .item a .meta {  margin-bottom: 7px; padding-left: 122px; font-size: 1.1rem; line-height: 1.09091; overflow: hidden; }

#pickup .list .item a .meta .cat, #pickup .list .item a .meta .area {  float: left; padding: 3px 9px; border: 1px solid; text-align: center; }

#pickup .list .item a .meta .area {  color: #fff; }

#pickup .list .item a .meta.sightseen .cat, #pickup .list .item a .meta.sightseen .area {  border-color: #9e4fbf; }

#pickup .list .item a .meta.sightseen .cat {  color: #9e4fbf; }

#pickup .list .item a .meta.sightseen .area {  background: #9e4fbf; }

#pickup .list .item a .meta.gourmet .cat, #pickup .list .item a .meta.gourmet .area {  border-color: #2198c4; }

#pickup .list .item a .meta.gourmet .cat {  color: #2198c4; }

#pickup .list .item a .meta.gourmet .area {  background: #2198c4; }

#pickup .list .item a .meta.event .cat, #pickup .list .item a .meta.event .area {  border-color: #71c72b; }

#pickup .list .item a .meta.event .cat {  color: #71c72b; }

#pickup .list .item a .meta.event .area {  background: #71c72b; }

#pickup .list .item a .meta.experience .cat, #pickup .list .item a .meta.experience .area {  border-color: #e6b33a; }

#pickup .list .item a .meta.experience .cat {  color: #e6b33a; }

#pickup .list .item a .meta.experience .area {  background: #e6b33a; }

#pickup .list .item a .meta.souvenir .cat, #pickup .list .item a .meta.souvenir .area {  border-color: #ef7e3a; }

#pickup .list .item a .meta.souvenir .cat {  color: #ef7e3a; }

#pickup .list .item a .meta.souvenir .area {  background: #ef7e3a; }

#pickup .list .item a .meta.hotel .cat, #pickup .list .item a .meta.hotel .area {  border-color: #e54c80; }

#pickup .list .item a .meta.hotel .cat {  color: #e54c80; }

#pickup .list .item a .meta.hotel .area {  background: #e54c80; }

#pickup .list .item a .meta.life .cat, #pickup .list .item a .meta.life .area {  border-color: #925848; }

#pickup .list .item a .meta.life .cat {  color: #925848; }

#pickup .list .item a .meta.life .area {  background: #925848; }

#pickup .list .item a .sub {  min-height: 30px; margin-bottom: 10px; padding-left: 122px; font-size: 1.4rem; font-weight: 500; }

#pickup .list .item a .date {  padding-top: 5px; border-top: 1px solid #e7e7e7; color: #8c8c8c; text-align: right; }

@media all and (min-width: 600px) {  #pickup .inner {  padding: 40px 0 50px; }
  #pickup .tit {  margin-bottom: 29px; padding-top: 57px; background-size: 78px auto; font-size: 3.5rem; letter-spacing: .14em; line-height: 1.4; }
  #pickup .list {  margin-right: -20px; padding-bottom: 30px; overflow: hidden; }
  #pickup .list .item {  float: left; width: 580px; margin: 0 20px 20px 0; }
  #pickup .list .item a {  min-height: 160px; padding: 19px; }
  #pickup .list .item a .pic {  top: 19px; left: 19px; width: 225px; }
  #pickup .list .item a .meta {  margin-bottom: 13px; padding-left: 255px; font-size: 1.2rem; line-height: 1.5; }
  #pickup .list .item a .meta .cat, #pickup .list .item a .meta .area {  padding: 2px 15px; }
  #pickup .list .item a .sub {  min-height: 30px; margin-bottom: 20px; padding-left: 255px; font-size: 1.7rem; line-height: 1.47059; }
  #pickup .list .item a .date {  width: 285px; margin: 0 0 0 auto; padding-top: 10px; font-size: 1.5rem; line-height: 1.4; } }

/* #gourmet
-------------------------------------------------*/
#gourmet .inner {  padding-top: 20px; padding-bottom: 25px; }

#gourmet .tit {  margin-bottom: 13px; padding-top: 32px; background: url(../img/home/ico_gourmet01.png) no-repeat 50% 0; background-size: 50px auto; font-size: 2rem; text-align: center; }

#gourmet .list {  margin: 0 -5px; padding-bottom: 6px; overflow: hidden; }

#gourmet .list .item {  float: left; width: 50%; margin-bottom: 19px; padding: 0 5px; box-sizing: border-box; }

#gourmet .list .item .pic {  margin-bottom: 7px; }

#gourmet .list .item .sub {  margin-bottom: 8px; font-size: 1.4rem; font-weight: 500; line-height: 1.42857; }

#gourmet .list .item .area {  display: inline-block; padding: 3px 12px; color: #2198c4; border: 1px solid #2198c4; font-size: 1.1rem; line-height: 1.09091; }

@media all and (min-width: 600px) {  #gourmet .inner {  padding: 40px 0 50px; }
  #gourmet .tit {  margin-bottom: 29px; padding-top: 57px; background-size: 96px auto; font-size: 3.5rem; letter-spacing: .14em; line-height: 1.4; }
  #gourmet .list {  margin: 0 -10px; padding-bottom: 40px; }
  #gourmet .list .item {  width: 300px; margin-bottom: 0; padding: 0 10px; }
  #gourmet .list .item .pic {  margin-bottom: 14px; }
  #gourmet .list .item .sub {  margin-bottom: 12px; font-size: 1.6rem; line-height: 1.5; }
  #gourmet .list .item .area {  padding: 2px 15px; font-size: 1.2rem; line-height: 1.5; } }

/* #info
-------------------------------------------------*/
#info {  background: url(../img/home/bg_info01.png) no-repeat 50% 50%; background-size: cover; }

#info .inner {  padding-top: 15px; padding-bottom: 24px; }

#info .box .news {  position: relative; margin-bottom: 25px; padding: 22px 20px 23px; background: rgba(255, 255, 255, 0.9); }

#info .box .news .tit {  padding-bottom: 15px; border-bottom: 1px solid #dcdcdc; font-size: 1.9rem; line-height: 1.57895; }

#info .box .news .list .item a {  padding: 12px 0; border-bottom: 1px solid #dcdcdc; }

#info .box .news .list .item a .date {  margin-bottom: 3px; color: #8c8c8c; font-size: 1.2rem; line-height: 1.5; }

#info .box .news .list .item a .sub {  color: #000; font-size: 1.3rem; }

#info .box .news .btn {  position: absolute; top: 22px; right: 20px; width: 120px; }

#info .box .news .btn a {  position: relative; display: block; height: 30px; padding-top: 6px; padding-right: 5px; border: 1px solid #4d4d4d; background: #4d4d4d; box-sizing: border-box; color: #fff; font-size: 1.1rem; font-weight: 500; text-align: center; }

#info .box .news .btn a::after {  position: absolute; top: 50%; right: 10px; width: 0; height: 0; content: ''; margin-top: -4px; border-style: solid; border-width: 4px 0 4px 4px; border-color: transparent transparent transparent #fff; }

@media all and (min-width: 600px) {  #info .inner {  padding-top: 70px; padding-bottom: 60px; overflow: hidden; }
  #info .facebook {  float: left; width: 300px; height: 424px; margin-right: 30px; }
  #info .box {  float: left; width: 310px; }
  #info .box .news {  margin-bottom: 30px; padding: 33px 30px 30px; }
  #info .box .news .tit {  padding-bottom: 14px; border-bottom: 1px solid #dcdcdc; font-size: 2.3rem; line-height: 1.30435; }
  #info .box .news .list {  margin-bottom: 25px; }
  #info .box .news .list .item a {  padding: 27px 0 32px; }
  #info .box .news .list .item a .date {  margin-bottom: 6px; font-size: 1.6rem; }
  #info .box .news .list .item a .sub {  font-size: 1.5rem; line-height: 1.73333; }
  #info .box .news .btn {  position: static; width: 150px; margin: 0 auto; }
  #info .box .news .btn a {  height: 35px; padding-right: 9px; font-size: 1.3rem; transition: all 0.4s ease-in-out; }
  #info .box .news .btn a::after {  right: 10px; }
  #info .box .news .btn a:hover {  color: #444; background: #fff; }
  #info .box .news .btn a:hover::after {  border-left-color: #444; }
  #info .box .about {  width: 270px; } }
